
@import "style/utilities/colors";
@import "style/utilities/variables";

.app__prefs {
  table {
    border-collapse: separate;
    text-align: start;
    width: 100%;
  }

  & td {
    padding: 12px 0;
  }

  & td.heading {
    padding-right: 16px;

    color: $slate200;
    font-size: $fs2XSmall;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    text-transform: uppercase;
  }

  &__masked__password {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    color: $slate200;
    font-size: $fs2XSmall;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
  }

  &__password {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: $grey900;
    border: 0;
    cursor: pointer;

    color: $pink500;
    font-size: $fs2XSmall;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    text-transform: uppercase;
  }
}
