@import "style/utilities/colors";
@import "style/utilities/variables";

div.toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  & span {
    color: $fgDefault;
    font-size: $fsCaption;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}

label.toggle-button {
  cursor: pointer;
  width: 40px;
  height: 20px;
  background: $accentSubtle;
  display: block;
  border-radius: 20px;
  position: relative;
  transition: 0.3s;

  >input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: $accentDefault;
    border-radius: 6px;
    transition: 0.3s;
  }

  &.checked {
    background: $accentDefault;

    &:after {
      left: calc(100% - 4px);
      transform: translateX(-100%);
      background: $accentSubtle;
    }
  }

  &.disabled:after {
    background: $accentMuted;
  }

  &.disabled {
    background: $accentSubtle;
  }

  &:active:after {
    width: 15px;
  }
}
