@import "style/utilities/colors";
@import "style/utilities/variables";

.user_context_container {
  width: max-content;

  margin: 0;
  padding: 8px 0;
  background: $grey950;

  display: inline-flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  border-radius: 4px;

  /* Shadow/Theme/60 */
  box-shadow: 0px 4px 8px 0px rgba(13, 13, 13, 0.90), 0px 0px 1px 0px #0D0D0D;

  >.user-info {
    display: flex;
    padding: 8px 16px;
    align-items: flex-start;
    gap: 8px;
  }

  & .user-info-text {
    display: flex;
    flex-direction: column;
    color: $fgDefault;
    font-size: $fsXSmall;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    >.email {
      font-size: $fs2XSmall;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
    }

    >.last-login {
      display: flex;
      margin-top: 8px;
      padding: 4px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2px;
      align-self: stretch;

      border-radius: 2px;
      border: 1px solid $slate400;
      background: $slate500;

      color: $slate200;

      font-size: $fs2XSmall;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
    }
  }

  >.items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    hr {
      width: 100%;
      height: 0.5px;
      margin: 0;
      padding: 0;
      background-color: $slate300;
      border: none;
    }
  }
}
