@import "style/utilities/colors";
@import "style/utilities/variables";

.dropdown-menu-item {
  width: fit-content;

  margin: 0;
  padding: 0px;
  width: 100%;
  background: $grey950;

  >a,
  >button {
    border: none;
    border-left: 2px solid $grey950;
    background: $grey950;
    color: $fgDefault;
    width: 100%;
    padding: 6px 16px;
    text-align: left;
    text-decoration: none;
    vertical-align: middle;
    font-size: $fsSmall;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    * {
      max-height: 16px;
    }
  }

  &.active,
  &:hover {

    >a,
    >button {
      border-left: 2px solid $accentDefault;
      color: $accentDefault;
    }
  }

  &__icon {
    margin-right: 8px;
  }
}
