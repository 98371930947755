@import "style/utilities/colors";
@import "style/utilities/variables";

.pill-button {
  border-radius: 1000px;
  padding: 6px 16px;
  height: 30px;
  display: flex;
  align-items: center;
  margin-right: 18px;
  white-space: nowrap;

  &__clickable {
    cursor: pointer;
  }

  &__val {
    padding-left: 8px;
    color: #f2f2f2;
    font-family: PT Mono;
  }

  &__icon {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s ease-in;
    width: 0;
  }

  &:hover,
  &:focus,
  &__focused {
    .pill-button__icon {
      display: flex;
      padding-left: 8px;
      visibility: visible;
      opacity: 1;
      width: 100%;

    }
    &.pill-button__with-icon {
      padding-right: 6px;
    }
  }

}

.pill-button__primary {
  background: $grey950;
  border: 0.5px solid $grey800;

  .pill-button__desc {
    color: $grey500;

    &--main {
      color: $fgDefault;
    }
  }

  &.pill-button:active, &.pill-button__active {
    border: 1px solid $accentEmphasis;
    background: $black;
  }
}

.pill-button__muted {
  background-color: $slate500;
  border: 1px solid $slate400;

  .pill-button__desc {
    color: $slate75;
  }

  .pill-button__icon {
    svg {
      fill: $fgDefault;
    }

  }
}

.pill-icon-button {
  background: $grey950;
  border: 0.5px solid $grey800;
  border-radius: 1000px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--selected {
    background: #3B4351;
  }

  &:hover {
    cursor: pointer;
  }
}
