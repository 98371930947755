@import "style/utilities/colors";
@import "style/utilities/variables";

.account {
  grid-area: page;
  justify-self: center;
  margin-top: 96px;
  margin-bottom: 96px;
  width: 100vh;

  &__header {
    color: $fgDefault;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Heebo;
    font-size: $fsLead;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 20px;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    &.menu {
      width: 235px;
      padding: 8px 0px;
      border-radius: 6px;
      background: $grey950;
      border: 1px solid $grey900;
      height: fit-content;
    }

    &.main {
      flex-grow: 1;
      gap: 10px;
    }
  }
}

.account__container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.account__accordion {
  &__accordion-item {
    border-radius: 6px;
    background: $grey950;
    width: 100%;
    padding: 12px 16px;
    border: 1px solid $grey900;
    cursor: pointer;

    &.expanded {
      background: $black;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    color: $fgDefault;
    align-items: center;
  }

  &__icon {
    margin-right: 8px;
  }

  &__spacer {
    flex-grow: 1;
  }

  &__arrow {
    transition: 0.3s;

    &.collapsed {
      transform: rotateZ(-180deg);
    }
  }
}
